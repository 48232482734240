/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 *
 * Note: The actual SVG icons should be part of a theme, but for now, we
 *       include them as a global static file.
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Internal dependencies
import { firstToUpperCase } from '@rakuten/common-util/string';
import { ThemeContext } from '@rakuten/contexts/ThemeContext';
import useStyleSystem from '@rakuten/hooks/useStyleSystem';
import {
  Platform,
  SvgXml,
  View
} from '@rakuten/modules-core-components/';
import { isStringValidator, onPressValidator } from '~resources/extensions/propTypes';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

/**
 * Name for icon sizes to make them more usable/recognizable.
 *
 * @type {object}
 */
const SIZE = {
  DEFAULT: 'default',
  LARGE: 'large',
  XLARGE: 'xlarge',
  XXLARGE: 'xxlarge',
  SMALL: 'small'
};


/**
 * Identifiers for the color variant used for the fill color of the icon
 *
 * @type {object}
 */
const COLOR_VARIANT = {
  PRIMARY: 'primary',
  BACKGROUND: 'background',
  DEFAULT: 'default',
  PRIMARY_DISABLED: 'primaryDisabled',
  ICON_DISABLED: 'iconDisabled',
  NONE: 'none',
  WHITE: 'white'
};


/**
 * Identifiers for the disabled color variant used for the fill color of the icon
 *
 * @type {object}
 */
const DISABLED_VARIANT = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SELECTED: 'selected',
  PRESSED: 'pressed'
};


/**
 * Map of identifiers for variants of the icon display
 *
 * @type {Object}
 */
const DISPLAY_VARIANT = {
  BORDER_ACTIVE: "borderActive",
  INVERTED_ACTIVE: "invertedActive"
};


/**
 * Identifiers for Selection Controls ( e.g. CheckBox, Switch (Toggle), RadioButton )
 *
 * @type {Object}
 */
const SELECTION_CONTROL = {
  SELECTED: "selectionControlSelected",
  NOT_SELECTED: "selectionControl"
};

// -----------------------------------------------------------------------------
// STYLES
// -----------------------------------------------------------------------------

/**
 * Calculate styles for this component, optionally based on props, theme,
 * currently matching breakpoint etc.
 *
 * @param {object} args Arguments used for style calculation
 * @param {object} args.props Current props passed to the component
 * @param {object} args.theme Current theme object
 * @return {object} Object that maps style names to style objects
 */
function createStyles({ props, theme }) {
  const { colors, variables, mixins } = theme;
  const { onPress, size, selectionControl } = props;

  // If the `onPress` prop is set, the touch area of the icon will be a fixed
  // height and width (see variables). In order to show the icon itself with the
  // correct size within the touch area, we need to set padding for the icon.
  let iconSize = onPress ? variables.iconSizeTouch : variables.iconSize[size];
  let iconPadding = onPress ? variables.iconSizePadding[size] : 0;

  if (selectionControl) {
    iconSize = variables.iconSizeSelectionControl;
    iconPadding = variables.iconPaddingSelectionControl;
  }

  const styleBorderActive = {
    borderRadius: "50%",
    fill: colors.backgroundUI,
    ":hover": {
      fill: colors.backgroundUI
    }
  };

  const selectionControlFocusStyle = {
    border: mixins.basicUnit(.25) + ' solid ' + colors.transparent,

    ':focus-visible': {
      borderColor: colors.background,
      outline: mixins.basicUnit(.25) + ' solid ' + colors.icon
    }
  };

  const disableHover = {
    ":hover": {
      backgroundColor: colors.transparent
    }
  }

  return {
    icon: {
      display: "flex",
      height: "100%",
      width: "100%"
    },

    // This extra property would usually not be necessary, the hover styles
    // should be added to the `icon` property above. But adding the styles
    // above breaks other styles
    clickable: {
      ':hover': {
        cursor: 'pointer',
        fill: colors.primary
      }
    },

    colorVariantPrimary: {
      fill: colors.primary
    },

    colorVariantBackground: {
      fill: colors.background
    },

    colorVariantDefault: {
      fill: colors.icon
    },

    colorVariantPrimaryDisabled: {
      fill: colors.primaryDisabled
    },

    colorVariantIconDisabled: {
      fill: colors.iconDisabled
    },

    colorVariantNone: {
      // color variant `none` applies this class w/o styles -> same as "inherit"
    },

    colorVariantWhite: {
      fill: colors.white
    },

    active: {
      fill: colors.primary
    },

    borderActive: {
      ...styleBorderActive,
      backgroundColor: colors.iconActive
    },

    invertedActive: {
      ...styleBorderActive,
      backgroundColor: colors.icon
    },

    size: {
      height: iconSize,
      minWidth: iconSize,
      padding: iconPadding,
      width: iconSize,
      borderRadius: "50%"
    },

    selectionControl: {
      ...selectionControlFocusStyle,
      ":hover": {
        backgroundColor: colors.iconHover
      }
    },

    selectionControlSelected: {
      ...selectionControlFocusStyle,
      ":hover": {
        backgroundColor: colors.primaryHover
      }
    },

    disabledVariantDefault: {
      fill: colors.iconDisabled,
      ...disableHover
    },

    disabledVariantPrimary: {
      fill: colors.primaryDisabled,
      ...disableHover
    },

    disabledVariantSelected: {
      fill: colors.iconSelected,
      ...disableHover
    },

    disabledVariantPressed: {
      fill: colors.iconPressed,
      ...disableHover
    }
  };
}


const displayVariantToStyle = {
  [DISPLAY_VARIANT.BORDER_ACTIVE]: "borderActive",
  [DISPLAY_VARIANT.INVERTED_ACTIVE]: "invertedActive"
};

const selectionControlToStyle = {
  [SELECTION_CONTROL.NOT_SELECTED]: "selectionControl",
  [SELECTION_CONTROL.SELECTED]: "selectionControlSelected"
};


// -----------------------------------------------------------------------------
// ICON COMPONENT
// -----------------------------------------------------------------------------

function Icon(props) {
  const context = useContext(ThemeContext);
  const iconFile = context.theme.icons;
  const {
    onPress,
    onKeyPress,
    size,
    icon,
    disabled,
    active,
    colorVariant,
    disabledVariant,
    displayVariant,
    selectionControl,
    accessible,
    accessibilityRole,
    accessibilityLabel,
    accessibilityState,
    accessibilityHasPopup,
    testID
  } = props;

  // "Hook" this component into the theme/style system
  const [ styles ] = useStyleSystem(
    {
      id: 'Icon',
      props,
      styleFn: createStyles
    },
    [
      size,
      Boolean(disabled),
      Boolean(active)
    ]
  );


  // ---------------------------------------------------------------------------
  // RENDER
  // ---------------------------------------------------------------------------

  const appliedStyles = [
    styles.size,
    active && (styles[displayVariantToStyle[displayVariant]] || styles.active),
    colorVariant && styles[`colorVariant${ firstToUpperCase(colorVariant) }`],
    !disabled && !colorVariant && styles.colorVariantDefault,
    selectionControl && styles[selectionControlToStyle[selectionControl]],
    disabled && disabledVariant && styles[`disabledVariant${ firstToUpperCase(disabledVariant) }`]
  ];

  if (onPress && !disabled
    && (!active || !displayVariant)
    && !selectionControl) {
    appliedStyles.push(styles.clickable);
  }

  const iconStyles = [
    styles.icon
  ];

  const accessibilityProps = {
    accessible: accessible !== undefined ? accessible : (onKeyPress || onPress) ? true : undefined,
    accessibilityLabel,
    accessibilityHasPopup,
    accessibilityState
  };
  if (onPress) {
    // Check if an a11yRole is set otherwise 'imagebutton'.
    // Otherwise we may lost some deliberately set roles like: 'checkbox'.
    accessibilityProps.accessibilityRole = accessibilityRole ? accessibilityRole : "imagebutton";
    if (disabled) {
      accessibilityProps.accessibilityState = { disabled: disabled };
    }
  } else {
    accessibilityProps.accessibilityRole = accessibilityRole || "image";
  }

  // for web platforms, `xml` contains SVG `<use>` with the icon reference,
  // otherwise it is the content of the icon file
  const xml = Platform.OS === "web"
    ? `<use href="${ iconFile }#icon-${ icon }" />`
    : iconFile[icon];

  // For native platforms, we need to apply the fill color to the XML, not
  // only to the enclosing view
  if (Platform.OS !== 'web') {
    iconStyles.push(
      colorVariant
      && styles[`colorVariant${ firstToUpperCase(colorVariant) }`]
      || styles.colorVariantDefault
    );
  }

  return (
    <View
      onPress={ !disabled ? onPress : undefined }
      onKeyPress={ !disabled
        ? onKeyPress ? onKeyPress : onPress
        : undefined
      }
      style={ appliedStyles }
      testID={ testID }
      { ...accessibilityProps }
    >
      <SvgXml
        style={ iconStyles }
        xml={ xml }
      />
    </View>
  );
}


// -----------------------------------------------------------------------------
// PROPS VALIDATION
// -----------------------------------------------------------------------------

Icon.propTypes = {
  /** The text for a11y systems, required if prop `onPress` is defined */
  accessibilityLabel: PropTypes.oneOfType([ isStringValidator, onPressValidator ]),

  /** Whether this icon is currently active or not [optional] */
  active: PropTypes.bool,

  /** The color variant to use for the icon's fill color [optional] */
  colorVariant: PropTypes.oneOf(Object.values(COLOR_VARIANT)),

  /** Whether or not the icon should be visualized in a disabled state [optional] */
  disabled: PropTypes.bool,

  /** The disabledColor variant to use for the icon's fill color [optional] */
  disabledVariant: PropTypes.oneOf(Object.values(DISABLED_VARIANT)),

  /** The icon's display variant [optional] */
  displayVariant: PropTypes.oneOf(Object.values(DISPLAY_VARIANT)),

  /** Display the icon as Selection Control [optional] */
  selectionControl: PropTypes.oneOf(Object.values(SELECTION_CONTROL)),

  /** The icon name/identifier */
  icon: PropTypes.string.isRequired,

  /** A function that is executed when the icon is pressed/tapped [optional] */
  onPress: PropTypes.func,

  /** The size of the icon [optional] */
  size: PropTypes.oneOf(Object.values(SIZE)),

  /** ID for test automation [optional] */
  testID: PropTypes.string,

  /** A list of additional styles to be added to the icon [optional] */
  additionalStyles: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ])
};

Icon.defaultProps = {
  active: false,
  size: SIZE.DEFAULT,
  disabledVariant: DISABLED_VARIANT.DEFAULT,
  testID: null
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default Icon;
export {
  COLOR_VARIANT,
  DISABLED_VARIANT,
  DISPLAY_VARIANT,
  SIZE,
  SELECTION_CONTROL
};
