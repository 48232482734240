/**
 * ██╗  ██╗ ██████╗ ██████╗  ██████╗
 * ██║ ██╔╝██╔═══██╗██╔══██╗██╔═══██╗
 * █████╔╝ ██║   ██║██████╔╝██║   ██║
 * ██╔═██╗ ██║   ██║██╔══██╗██║   ██║
 * ██║  ██╗╚██████╔╝██████╔╝╚██████╔╝
 * ╚═╝  ╚═╝ ╚═════╝ ╚═════╝  ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

/* eslint-disable */

/**
 * postal - Pub/Sub library providing wildcard subscriptions, complex message handling, etc.  Works server and client-side.
 * Author: Jim Cowart (http://ifandelse.com)
 * Version: v2.0.6
 * Url: http://github.com/postaljs/postal.js
 * License(s): MIT
 */

var prevPostal = window && window.postal;

var _defaultConfig = {
  DEFAULT_CHANNEL: "/",
  SYSTEM_CHANNEL: "postal",
  enableSystemMessages: true,
  cacheKeyDelimiter: "|",
  autoCompactResolver: false
};

var postal = {
  configuration: Object.assign({}, _defaultConfig)
};
var _config = postal.configuration;


var ChannelDefinition = function (channelName, bus) {
  this.bus = bus;
  this.channel = channelName || _config.DEFAULT_CHANNEL;
};

ChannelDefinition.prototype.subscribe = function () {
  return this.bus.subscribe({
    channel: this.channel,
    topic: (arguments.length === 1 ? arguments[0].topic : arguments[0]),
    callback: (arguments.length === 1 ? arguments[0].callback : arguments[1])
  });
};

/*
    publish( envelope [, callback ] );
    publish( topic, data [, callback ] );
*/
ChannelDefinition.prototype.publish = function () {
  var envelope = {};
  var callback;
  if (typeof arguments[0] === "string") {
    envelope.topic = arguments[0];
    envelope.data = arguments[1];
    callback = arguments[2];
  } else {
    envelope = arguments[0];
    callback = arguments[1];
  }
  if (typeof envelope !== "object") {
    throw new Error("The first argument to ChannelDefinition.publish should be either an envelope object or a string topic.");
  }
  envelope.headers = Object.assign(envelope.headers || {}, { resolverNoCache: _config.resolverNoCache });
  envelope.channel = this.channel;
  this.bus.publish(envelope, callback);
};


var SubscriptionDefinition = function (channel, topic, callback) {
  if (arguments.length !== 3) {
    throw new Error("You must provide a channel, topic and callback when creating a SubscriptionDefinition instance.");
  }
  if (topic.length === 0) {
    throw new Error("Topics cannot be empty");
  }
  this.channel = channel;
  this.topic = topic;
  this.callback = callback;
  this.pipeline = [];
  this.cacheKeys = [];
  this._context = undefined;
};

SubscriptionDefinition.prototype = {

  "catch": function (errorHandler) {
    var original = this.callback;
    var safeCallback = function () {
      try {
        original.apply(this, arguments);
      } catch (err) {
        errorHandler(err, arguments[0]);
      }
    };
    this.callback = safeCallback;
    return this;
  },

  invokeSubscriber: function invokeSubscriber(data, env) {
    if (!this.inactive) {
      var self = this;
      var pipeline = self.pipeline;
      var len = pipeline.length;
      var context = self._context;
      var idx = -1;
      var invoked = false;
      if (!len) {
        self.callback.call(context, data, env);
        invoked = true;
      } else {
        pipeline = pipeline.concat([self.callback]);
        var step = function step(d, e) {
          idx += 1;
          if (idx < len) {
            pipeline[idx].call(context, d, e, step);
          } else {
            self.callback.call(context, d, e);
            invoked = true;
          }
        };
        step(data, env, 0);
      }
      return invoked;
    }
  },

  subscribe: function subscribe(callback) {
    this.callback = callback;
    return this;
  },

  unsubscribe: function unsubscribe() {

    if (!this.inactive) {
      postal.unsubscribe(this);
    }
  }

};

_config.resolver = {
  cache: {},
  regex: {},
  enableCache: true,

  compare: function compare(binding, topic, headerOptions) {
    var pattern;
    var rgx;
    var prevSegment;
    var cacheKey = topic + _config.cacheKeyDelimiter + binding;
    var result = (this.cache[cacheKey]);
    var opt = headerOptions || {};
    var saveToCache = this.enableCache && !opt.resolverNoCache;
    // result is cached?
    if (result === true) {
      return result;
    }
    // plain string matching?
    if (binding.indexOf("#") === -1 && binding.indexOf("*") === -1) {
      result = (topic === binding);
      if (saveToCache) {
        this.cache[cacheKey] = result;
      }
      return result;
    }
    // ah, regex matching, then
    if (!(rgx = this.regex[binding])) {
      pattern = "^" + binding.split(".").map((segment) => {
        var res = "";
        if (!!prevSegment) {
          res = prevSegment !== "#" ? "\\.\\b" : "\\b";
        }
        if (segment === "#") {
          res += "[\\s\\S]*";
        } else if (segment === "*") {
          res += "[^.]+";
        } else {
          res += segment;
        }
        prevSegment = segment;
        return res;
      }).join("") + "$";
      rgx = this.regex[binding] = new RegExp(pattern);
    }
    result = rgx.test(topic);
    if (saveToCache) {
      this.cache[cacheKey] = result;
    }
    return result;
  },

  reset: function reset() {
    this.cache = {};
    this.regex = {};
  },

  purge: function (options) {
    var self = this;
    var keyDelimiter = _config.cacheKeyDelimiter;
    var matchPredicate = function (val, key) {
      var split = key.split(keyDelimiter);
      var topic = split[0];
      var binding = split[1];
      if ((typeof options.topic === "undefined" || options.topic === topic) &&
        (typeof options.binding === "undefined" || options.binding === binding)) {
        delete self.cache[key];
      }
    };

    var compactPredicate = function (val, key) {
      var split = key.split(keyDelimiter);
      if (postal.getSubscribersFor({ topic: split[0] }).length === 0) {
        delete self.cache[key];
      }
    };

    if (typeof options === "undefined") {
      this.reset();
    } else {
      var handler = options.compact === true ? compactPredicate : matchPredicate;
      Object.keys(this.cache || {}).forEach(key => handler(this.cache[key], key));
    }
  }
};




var pubInProgress = 0;
var unSubQueue = [];
var autoCompactIndex = 0;

function clearUnSubQueue() {
  while (unSubQueue.length) {
    postal.unsubscribe(unSubQueue.shift());
  }
}

function getCachePurger(subDef, key, cache) {
  return function (sub, i, list) {
    if (sub === subDef) {
      list.splice(i, 1);
    }
    if (list.length === 0) {
      delete cache[key];
    }
  };
}

function getCacher(topic, pubCache, cacheKey, done, envelope) {
  var headers = envelope && envelope.headers || {};
  return function (subDef) {
    var cache;
    if (_config.resolver.compare(subDef.topic, topic, headers)) {
      if (!headers.resolverNoCache) {
        cache = pubCache[cacheKey] = (pubCache[cacheKey] || []);
        cache.push(subDef);
        subDef.cacheKeys.push(cacheKey);
      }
      if (done) {
        done(subDef);
      }
    }
  };
}

function getSystemMessage(kind, subDef) {
  return {
    channel: _config.SYSTEM_CHANNEL,
    topic: "subscription." + kind,
    data: {
      event: "subscription." + kind,
      channel: subDef.channel,
      topic: subDef.topic
    }
  };
}

var sysCreatedMessage = getSystemMessage.bind(undefined, "created");
var sysRemovedMessage = getSystemMessage.bind(undefined, "removed");

function getPredicate(options, resolver) {
  if (typeof options === "function") {
    return options;
  } else if (!options) {
    return function () {
      return true;
    };
  } else {
    return function (sub) {
      var compared = 0;
      var matched = 0;
      Object.keys(options || {}).forEach(key => {
        compared += 1;
        if (
          // We use the bindings resolver to compare the options.topic to subDef.topic
          (key === "topic" && resolver.compare(sub.topic, options.topic, { resolverNoCache: true })) ||
          (key === "context" && options.context === sub._context) ||
          // Any other potential prop/value matching outside topic & context...
          (sub[key] === options[key])) {
          matched += 1;
        }
      });
      return compared === matched;
    };
  }
}

postal = Object.assign(postal, {
  cache: {},
  subscriptions: {},
  wireTaps: [],

  ChannelDefinition: ChannelDefinition,
  SubscriptionDefinition: SubscriptionDefinition,

  channel: function channel(channelName) {
    return new ChannelDefinition(channelName, this);
  },

  addWireTap: function addWireTap(callback) {
    var self = this;
    self.wireTaps.push(callback);
    return function () {
      var idx = self.wireTaps.indexOf(callback);
      if (idx !== -1) {
        self.wireTaps.splice(idx, 1);
      }
    };
  },

  noConflict: function noConflict() {
    window.postal = prevPostal;
    return this;
  },

  getSubscribersFor: function getSubscribersFor(options) {
    var result = [];
    var self = this;
    Object.keys(self.subscriptions || {}).forEach((channel) => {
      Object.keys(self.subscriptions[channel] || {}).forEach(key => {
        result = result.concat(self.subscriptions[channel][key].filter(getPredicate(options, _config.resolver)));
      });
    });
    return result;
  },

  publish: function publish(envelope, cb) {
    ++pubInProgress;
    var channel = envelope.channel = envelope.channel || _config.DEFAULT_CHANNEL;
    var topic = envelope.topic;
    envelope.timeStamp = new Date();
    if (this.wireTaps.length) {
      this.wireTaps.forEach(function (tap) {
        tap(envelope.data, envelope, pubInProgress);
      });
    }
    var cacheKey = channel + _config.cacheKeyDelimiter + topic;
    var cache = this.cache[cacheKey];
    var skipped = 0;
    var activated = 0;
    if (!cache) {
      var cacherFn = getCacher(
        topic,
        this.cache,
        cacheKey,
        function (candidate) {
          if (candidate.invokeSubscriber(envelope.data, envelope)) {
            activated++;
          } else {
            skipped++;
          }
        },
        envelope
      );
      Object.keys(this.subscriptions[channel] || {}).forEach(key => {
        const candidates = this.subscriptions[channel][key];
        candidates.forEach(cacherFn);
      });
    } else {
      (cache || []).forEach(function (subDef) {
        if (subDef.invokeSubscriber(envelope.data, envelope)) {
          activated++;
        } else {
          skipped++;
        }
      });
    }
    if (--pubInProgress === 0) {
      clearUnSubQueue();
    }
    if (cb) {
      cb({
        activated: activated,
        skipped: skipped
      });
    }
  },

  reset: function reset() {
    this.unsubscribeFor();
    _config.resolver.reset();
    this.subscriptions = {};
    this.cache = {};
  },

  subscribe: function subscribe(options) {
    var subscriptions = this.subscriptions;
    var subDef = new SubscriptionDefinition(options.channel || _config.DEFAULT_CHANNEL, options.topic, options.callback);
    var channel = subscriptions[subDef.channel];
    var channelLen = subDef.channel.length;
    var subs;
    if (!channel) {
      channel = subscriptions[subDef.channel] = {};
    }
    subs = subscriptions[subDef.channel][subDef.topic];
    if (!subs) {
      subs = subscriptions[subDef.channel][subDef.topic] = [];
    }
    // First, add the SubscriptionDefinition to the channel list
    subs.push(subDef);
    // Next, add the SubscriptionDefinition to any relevant existing cache(s)
    var cache = this.cache;
    Object.keys(cache || {}).forEach(function (cacheKey) {
      if (cacheKey.substr(0, channelLen) === subDef.channel) {
        getCacher(
          cacheKey.split(_config.cacheKeyDelimiter)[1],
          cache,
          cacheKey)(subDef);
      }
    });

    if (_config.enableSystemMessages) {
      this.publish(sysCreatedMessage(subDef));
    }
    return subDef;
  },

  unsubscribe: function unsubscribe() {
    var unSubLen = arguments.length;
    var unSubIdx = 0;
    var subDef;
    var channelSubs;
    var topicSubs;
    var idx;
    for (; unSubIdx < unSubLen; unSubIdx++) {
      subDef = arguments[unSubIdx];
      subDef.inactive = true;
      if (pubInProgress) {
        unSubQueue.push(subDef);
        return;
      }
      channelSubs = this.subscriptions[subDef.channel];
      topicSubs = channelSubs && channelSubs[subDef.topic];

      if (topicSubs) {
        var len = topicSubs.length;
        idx = 0;
        // remove SubscriptionDefinition from channel list
        while (idx < len) {

          if (topicSubs[idx] === subDef) {
            topicSubs.splice(idx, 1);
            break;
          }
          idx += 1;
        }
        if (topicSubs.length === 0) {
          delete channelSubs[subDef.topic];
          if (!Object.keys(channelSubs || {}).length) {
            delete this.subscriptions[subDef.channel];
          }
        }
        // remove SubscriptionDefinition from postal cache
        if (subDef.cacheKeys && subDef.cacheKeys.length) {
          var key;
          while (key = subDef.cacheKeys.pop()) {
            var purger = getCachePurger(subDef, key, this.cache);
            this.cache[key].forEach(purger);
          }
        }
        if (typeof _config.resolver.purge === "function") {
          // check to see if relevant resolver cache entries can be purged
          var autoCompact = _config.autoCompactResolver === true ?
            0 : typeof _config.autoCompactResolver === "number" ?
              (_config.autoCompactResolver - 1) : false;
          if (autoCompact >= 0 && autoCompactIndex === autoCompact) {
            _config.resolver.purge({ compact: true });
            autoCompactIndex = 0;
          } else if (autoCompact >= 0 && autoCompactIndex < autoCompact) {
            autoCompactIndex += 1;
          }
        }
      }
      if (_config.enableSystemMessages) {
        this.publish(sysRemovedMessage(subDef));
      }
    }
  },

  unsubscribeFor: function unsubscribeFor(options) {
    var toDispose = [];

    if (this.subscriptions) {
      toDispose = this.getSubscribersFor(options);
      this.unsubscribe.apply(this, toDispose);
    }
  }
});

export default postal;
