/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import { PURGE as REDUX_PURGE } from 'redux-persist';

// Internal dependencies
import { resolveThemeName } from '@rakuten/common-util/theme';
import { validateSchema } from '@rakuten/schemas/utils';
import rxSchema from '@rakuten/schemas/reducers/rx.schema';
import {
  APP_THEME_CHANGED,
  RX_EXTERNAL_LINKS_GRANTED,
  RX_FULLTEXTSEARCH_HISTORY_ADDED,
  RX_FULLTEXTSEARCH_HISTORY_CLEARED,
  RX_FULLTEXTSEARCH_RESULT_ADDED,
  RX_FULLTEXTSEARCH_RESULT_CLEARED,
  RX_FULLTEXTSEARCH_RESULT_SET,
  RX_PUBLISHERS_DEFAULT,
  RX_FONTSIZE_CHANGE,
  RX_SETTINGS_UPDATED
} from '../actions/types';
import { updateState } from '../utils';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

const DEFAULT_STATE = {
  externalLinksGranted: false,
  fullTextSearch: {
    history: [ ],
    results: [ ]
  },
  settings: {
    fontFamily: 'default',
    fontSize: 7,
    fontWeight: 400,
    letterSpacing: 'default',
    lineSpacing: 'default',
    pageSpread: 0, // 0 = auto, 1 = none (single page), 2 = both (double page for both orientations)
    pageMargins: 'small',
    readingMode: 'paged',
    textAlign: 'default',
    theme: 'theme-tolino-light', // @ToDo: what if the initial state is derived from system?
    wordSpacing: 'default',

    isPublishersDefault: true
  },
  // user settings to be restored if the publisher default is set to `false` again
  userSettings: {}
};


/**
 * Property names defining a "publisher default", representing the default font
 * settings of a publication. Other properties such as `fontSize` or `theme`
 * do _not_ affect the publisher standard!
 */
const PUBLISHER_DEFAULT_PROPS = [
  "fontFamily",
  "textAlign",
  "letterSpacing",
  "fontWeight",
  "lineSpacing",
  "wordSpacing"
];


// -----------------------------------------------------------------------------
// AUXILIARY FUNCTIONS
// -----------------------------------------------------------------------------

/**
 * Indicates whether @settings match the publication's default settings
 *
 * @param {Object} settings Font settings
 * @return {Boolean} Indicator if @settings are matching the default state
 */
function isPublishersDefault(settings) {
  return PUBLISHER_DEFAULT_PROPS.every(
    (propName) => settings[propName] === DEFAULT_STATE.settings[propName]
  );
}


/**
 * Applies @isPublishersDefault to @state and returns the updated state.
 *
 * @param  {Object} state Current font settings
 * @param  {Boolean} isPublishersDefault Indicator to apply the publisher's default
 * @return {Object} Updated state according to @isPublishersDefault
 */
function applyPublishersDefault(state, isPublishersDefault) {
  const newState = updateState(state, { settings: { isPublishersDefault }});

  if (isPublishersDefault) {
    // save current font settings for all properties qualifying a publisher's
    // standard and reset the font settings accordingly:
    const userSettings = newState.userSettings = {};

    PUBLISHER_DEFAULT_PROPS.forEach((propName) => {
      userSettings[propName] = state.settings[propName];
      newState.settings[propName] = DEFAULT_STATE.settings[propName];
    });
  }
  else {
    // Publisher's default disabled -> restore previous user settings:
    PUBLISHER_DEFAULT_PROPS.forEach((propName) => {
      newState.settings[propName] = state.userSettings[propName] || DEFAULT_STATE.settings[propName];
    });
  }

  validateSchema(newState, rxSchema);

  return newState;
}


// -----------------------------------------------------------------------------
// RX REDUCER
// -----------------------------------------------------------------------------

function rx(state = DEFAULT_STATE, action) {

  function updateAndValidate(updates) {
    const newState = updateState(state, updates);
    if (newState !== state) {
      validateSchema(newState, rxSchema);
    }
    return newState;
  }


  switch (action.type) {
    case APP_THEME_CHANGED:
      return updateAndValidate({
        settings: {
          theme: resolveThemeName(action.payload.theme, state.settings.theme)
        }
      });

    case RX_EXTERNAL_LINKS_GRANTED:
      return updateAndValidate({
        externalLinksGranted: action.isGranted
      });

    case RX_FULLTEXTSEARCH_HISTORY_ADDED: {
      // Make sure, stored search terms are unique. If a previous search term
      // is submitted again, it is hoisted to the top of the list
      const isAvailable = state.fullTextSearch.history.indexOf(action.payload.entry);
      if (isAvailable === 0) {
        // Search term already at the top of the list
        return state;
      }
      let items = state.fullTextSearch.history.slice();
      if (isAvailable > 0) {
        items.splice(isAvailable, 1);
      }
      items.unshift(action.payload.entry);
      return updateAndValidate({
        fullTextSearch: { history: items }
      });
    }

    case RX_FULLTEXTSEARCH_HISTORY_CLEARED:
      return updateAndValidate({
        fullTextSearch: { history: DEFAULT_STATE.fullTextSearch.history }
      });

    case RX_FULLTEXTSEARCH_RESULT_ADDED: {
      let results = state.fullTextSearch.results.slice();
      results.push(action.payload.entry);
      return updateAndValidate({
        fullTextSearch: { results }
      });
    }

    case RX_FULLTEXTSEARCH_RESULT_SET:
      return updateAndValidate({
        fullTextSearch: action.payload
      });

    case RX_FULLTEXTSEARCH_RESULT_CLEARED:
      return updateAndValidate({
        fullTextSearch: { results: DEFAULT_STATE.fullTextSearch.results }
      });

    case RX_PUBLISHERS_DEFAULT:
      return action.isPublishersDefault === state.settings.isPublishersDefault
        ? state
        : applyPublishersDefault( state, action.isPublishersDefault );

    case RX_FONTSIZE_CHANGE: {
      const { increase, limits: { MIN, MAX } } = action.payload;
      const { fontSize } = state.settings;
      const newFontSize = increase ? fontSize + 1 : fontSize - 1;
      const canUpdate = newFontSize >= MIN && newFontSize <= MAX;

      if ( !canUpdate ) {
        return state;
      }

      return updateState( state, {
        settings: {
          ...state.settings,
          fontSize: newFontSize
        }
      } );
    }

    case RX_SETTINGS_UPDATED: {
      const newState = updateState(state, { settings: action.payload.settings });
      if (newState !== state) {
        // `settings` have changed -> recalculate the publisher's default state
        newState.settings.isPublishersDefault = isPublishersDefault(newState.settings);
        validateSchema(newState, rxSchema);
      }
      return newState;
    }

    case REDUX_PURGE:
      return updateAndValidate(DEFAULT_STATE);
  }

  return state;
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default rx;
