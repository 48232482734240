/**
 *  ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗  █████╗ ██████╗ ██████╗
 *  ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗██╔══██╗██╔══██╗██╔══██╗
 *     ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║███████║██████╔╝██████╔╝
 *     ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║██╔══██║██╔═══╝ ██╔═══╝
 *     ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝██║  ██║██║     ██║
 *     ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
 *
 * (c) Copyright 2018-present Rakuten Kobo Inc. (http://www.kobo.com)
 */

// =============================================================================
// AUXILIARY CONSTANTS AND FUNCTIONS
// =============================================================================

const isClassName = (arg) =>
  typeof arg === "string" && arg !== "";

const assignObject = (target, source) =>
  Object.isExtensible(target)
    ? Object.assign(target, source)
    : Object.assign({}, target, source);

const mergeStyle = (props, style) =>
  props.style = props.style
    ? assignObject(props.style, style)
    : style;

const appendClassNames = (props, classNames) =>
  props["className"] = props["className"]
    ? props["className"] + " " + classNames
    : classNames;

const IGNORE = () => null;

const isDefined = (value) =>
  value !== undefined && value !== null && !isNaN(value);


// =============================================================================
// MAPPING NATIVE PROPS -> HTML ATTRIBUTES
// =============================================================================

/**
 * Map of `resizeMode` keys to their corresponding CSS `object-fit` values.
 * See https://reactnative.dev/docs/image#resizemode
 * and https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit
 *
 * @type {Object<String>}
 */
export const RESIZE_MODE = {
  cover:   "cover",
  contain: "contain",
  stretch: "fill",
  repeat:  "none",
  center:  "scale-down"
};


/**
 * Definition/mapping of RN accessibility identifiers, used to validate property
 * values or to map the key/value combinations to HTML attributes.
 * See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles
 * and https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques
 */
const ACCESSIBILITY = {
  // ROLE: empty strings will map to identity values, e.g. "alert" -> "alert"
  ROLE: {
    "adjustable":   "slider", // or "meter"
    "alert":        "",
    "button":       "",
    "checkbox":     "",
    "combobox":     "",
    "grid":         "",
    "header":       "heading", // usually along with `aria-level` 1 .. 6
    "image":        "img",
    "imagebutton":  "button",
    "keyboardkey":  "text", // correct mapping?
    "link":         "",
    "menu":         "",
    "menubar":      "",
    "menuitem":     "",
    "none":         "",
    "navigation":   "",
    "progressbar":  "",
    "radio":        "",
    "radiogroup":   "",
    "scrollbar":    "",
    "search":       "",
    "spinbutton":   "",
    "summary":      "note",
    "switch":       "",
    "tab":          "",
    "tablist":      "",
    "tabpanel":      "",
    "text":         "",
    "timer":        "",
    "togglebutton": "button",
    "toolbar":      ""
  },
  STATE: {
    "busy":     "aria-busy",
    "checked":  "aria-checked",
    "disabled": "aria-disabled",
    "expanded": "aria-expanded",
    "pressed":  "aria-pressed",
    "selected": "aria-selected"
  },
  VALUE: {
    "min":  "aria-valuemin",
    "max":  "aria-valuemax",
    "now":  "aria-valuenow",
    "text": "aria-valuetext"
  }
};


/**
 * Configuration of the property mapping RN -> web applicable to *every* component.
 * It contains a mapping of RN property names to transformation functions returning
 * Reactjs props. Each function is called with the target object and the property
 * value.
 * When a property should be removed (because it is not needed or not available
 * for web) then there should be an placeholder function, i.e. `IGNORE`
 */
const PROPERTY_MAP_ALL = {
  "_forwardedRef": IGNORE, // ignore "meta" property created by <LayoutContainer>
  "accessible": transformAccessible,
  "accessibleHidden": transformAccessibleHidden, //iOS
  "importantForAccessibility": transformAccessibleHidden, //android
  "accessibilityHint":  (props, value) => value && (props["aria-details"] = value),
  "accessibilityLabel": (props, value) => value && (props["aria-label"] = value),
  "accessibilityLabelledBy": (props, value) => value && (props["aria-labelledby"] = value),
  "accessibilityDescribedBy": (props, value) => value && (props["aria-describedby"] = value),
  "accessibilityLevel": (props, value) => props["aria-level"] = value,
  "accessibilityRole":  (props, value) => props["role"] = ACCESSIBILITY.ROLE[value] || value,
  "accessibilityState": transformAccessibilityState,
  "accessibilityValue": transformAccessibilityValue,
  "accessibilityViewIsModal": (props, value) => props["aria-modal"] = value,
  "accessibilityHasPopup": (props, value) => props["aria-haspopup"] = value,
  "accessibilityExpanded": (props, value) => props["aria-expanded"] = value,
  "nativeID": (props, value) => props["id"] = value,
  "testID": (props, value) => props["data-test-id"] = value,
  "testId": (props, value) => props["data-test-id"] = value,
  "onLayout": IGNORE, // this handler is already attached to <LayoutContainer>
  "onPress": (props, value) => props["onClick"] = value, // Button, Pressable, and Touchables: `onPress` -> `onClick`
  "onPressIn": (props, value) => props['onPointerDown'] = value, // Button, Pressable, and Touchables: `onPressIn` -> `onPointerDown`
  "onPressOut": (props, value) => props['onPointerUp'] = value, // Button, Pressable, and Touchables: `onPressOut` -> `onPointerUp`
  "style": transformStyle
};

/**
 * Configuration of the property mapping RN -> web applicable to *specific* components.
 * Each component-specific definition complements the mapping defined in
 * `PROPERTY_MAP_ALL` as can be seen in the loop below.
 */
const PROPERTY_MAP_COMPONENT = {
  Button: {
    color: (props, color) => mergeStyle(props, { color }),
    title: (props, value) => props["value"] = value
  },
  Image: {
    resizeMode: (props, mode) => mergeStyle(props, { "object-fit": RESIZE_MODE[mode] || "cover" }),
    source: transformImageSource
  },
  ImageBackground: {
    imageStyle: mergeStyle,
    source: (props, source) => mergeStyle(props, { backgroundImage: (typeof source === "object") ? source.uri : source })
  },
  SvgImage: {
    uri: transformImageSource
  },
  Text: {
    numberOfLines: applyLineClamp
  },
  TextInput: {
    // TextInput: map "numberOfLines" -> "rows" for <textarea> tags
    autoCompleteType: (props, value) => props["autoComplete"] = value,
    autoCorrect: (props, value) => props["autoCorrect"] = value ? "on" : "off",
    numberOfLines: (props, value) => props["rows"] = value,
    onChangeText: (props, value) => props["onInput"] = value,
    placeholderTextColor: IGNORE,
    resize: applyTextAreaResize
    // @ToDo: "onEndEditing", "onSubmitEditing"
  }
};

// Merge each component-specific property mapping with `PROPERTY_MAP_ALL`
for (let key in PROPERTY_MAP_COMPONENT) {
  PROPERTY_MAP_COMPONENT[key] = Object.assign({}, PROPERTY_MAP_ALL, PROPERTY_MAP_COMPONENT[key]);
}


/**
 * Transforms the RN prop `accessible` to web ARIA props.
 * When @value is `true`, it indicates that an element is accessible, grouping
 * its children into a single selectable component.
 * For the web, an element will be focusable by using attribute `tabindex`.
 * When @value is `false` or "false", attribute `aria-hidden` is applied.
 * see https://reactnative.dev/docs/accessibility#accessible
 *
 * @param {Object} props List of React element properties
 * @param {Boolean|String} value Value for property `accessible`
 */

function transformAccessible(props, value) {
  if (value === "true" || value === true) {
    // React prefers camelCase for DOM attribute `tabindex`
    props["tabIndex"] = 0;
  }
  else if (value === "false" || value === false)  {
    props["aria-hidden"] = true;
  }
}

// TODO: Check this with function transformAccessible(props, value)
// Maybe we need a refactor
function transformAccessibleHidden(props, value) {
  if (value === "true" || value === true) {
    props["aria-hidden"] = true;
  }
  else if (value === "false" || value === false)  {
    props["aria-hidden"] = false;
  }
}



function transformAccessibilityState(props, accStates) {
  if (!accStates || typeof accStates !== "object") {
    return;
  }
  for (let key in accStates) {
    if (key === "checked" && /button$/.test(props.role || props.accessibilityRole)) {
      // use state `aria-pressed` instead of `aria-checked` for (toggle-)buttons
      props["aria-pressed"] = accStates[key];
    }
    else if (key in ACCESSIBILITY.STATE) {
      props[ACCESSIBILITY.STATE[key]] = accStates[key];
    }
  }
}


function transformAccessibilityValue(props, accValue) {
  if (!accValue || typeof accValue !== "object") {
    return;
  }
  for (let key in accValue) {
    const attrName = ACCESSIBILITY.VALUE[key];
    if (attrName && isDefined(accValue[key])) {
      props[attrName] = accValue[key];
    }
  }
}


function transformImageSource(props, source) {
  props["src"] = (typeof source === "object") ? source.uri : source;
  props["alt"] = "";
}


/**
 * Applies the RN `numberOfLines` property as CSS style,
 * see https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
 * Note 1: with vendor prefix! Definition might be superseeded by
 * https://drafts.csswg.org/css-overflow-3/#propdef-line-clamp
 * Note 2: CSS Key "-vendor-specific-prop" becomes "VendorSpecificProp" for a
 * React style property
 *
 * @ToDo: move all style attributes except "-webkit-line-clamp" to a dedicated
 * class in a stylesheet object which is available in the document scope.
 *
 * @param {Object} props Target (HTML) properties
 * @param {Number} numLines number of visible lines
 */
function applyLineClamp(props, numLines) {
  // apply `line-clamp` only for positive @numLines values
  if (numLines > 0) {
    mergeStyle(props, {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: numLines,
      WebkitBoxOrient: "vertical"
    });
  }
}

/**
 * Applies the React `resize` property as CSS resize style, see
 * https://developer.mozilla.org/en-US/docs/Web/CSS/resize
 *
 * @param {object} Target properties
 * @param {string} resize The resize value
 */
function applyTextAreaResize(props, resize) {
  mergeStyle(props, {
    resize
  });
}


/**
 * Transforms a component's "style" property to HTML "className" and/or "style"
 * properties.
 * "style" is mapped to "className" if @style is a *string*,
 * it is mapped to "style" (HTML inline style) if @style is an *object*.
 * If @style is an array, both "className" and "style" attributes are created
 * depending on the array item's type.
 * Note: @style is _appended_ if there is already a "className" property.
 *
 * ToDo: check inline style for properties specific to RN, e.g. style={{ resizeMode: "cover" }}
 * or "contain", "stretch", "repeat", "center" -> "backgroundSize": "cover"
 *
 * @param {Object} props Target (HTML) properties
 * @param {String|Array|Object} style Style values
 */
function transformStyle(props, style) {
  if (isClassName(style)) {
    // "style" attributes of type "string" are mapped/appended to attribute "className"
    appendClassNames(props, style);
  }
  else if (Array.isArray(style)) {
    // "style" attributes of type "Array" may contain both class names _and_
    // inline styles (CSS style objects) -> apply `transformStyle` recursively
    style.forEach((item) => transformStyle(props, item));
  }
  else if (style && typeof style === "object") {
    // @style is an object -> HTML inline style: merge styles
    mergeStyle(props, style);
  }
  // console.log("%ctransformStyle, style:", "color: #049", style, "-> props", props);
}


/**
 * Transforms a React Native component's @nativeProps to Reactjs properties,
 * e.g. "testID" -> "data-test-id"

 * @param {Object} nativeProps React native properties
 * @param {String} componentName The React Native component name
 * @param {Object} [ref] React ref passed to the component
 * @return {Object} Transformed @nativeProps object
 */
export function transformProps(nativeProps, componentName, ref) {
  const mapPropName = PROPERTY_MAP_COMPONENT[componentName] || PROPERTY_MAP_ALL;
  const props = Object.create(null);

  for (let propName in nativeProps) {
    const fnMapProp = mapPropName[propName];
    if (fnMapProp) {
      // there's a mapping function for `propName`
      fnMapProp(props, nativeProps[propName]);
    }
    else {
      // otherwise copy the `propName` and value
      // console.log("%ctransformProps: passing property \"%s\"", "color: #f90", propName, nativeProps[propName]);
      props[propName] = nativeProps[propName];
    }
  }
  if (ref !== undefined) {
    props["ref"] = ref;
  }

  return props;
}
