/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import SHARED_GLOBALS from '~configuration/kobo-GLOBALS';
import BUILDINFO from './../static/intermediates/configuration/buildinfo.json';
import PKG from './../package.json';

let url;
let CONFIG;
if (BUNDLER_BUILD_MODE === 'development') {
  // During development, we can just synchronously import the configuration,
  // because it has been created during development setup, e.g. while calling
  // yarn run develop:server, and is thus available at build time
  url = new URL('./../static/intermediates/configuration/config.json', import.meta.url);
} else {
  // For production builds, we need to fetch the configuration synchronously,
  // because the file will dynamically be created in the deployment phase and
  // is not available at build time, but only at run time.
  url = 'intermediates/configuration/config.json';
}
let xhr = new XMLHttpRequest();
xhr.open('GET', url, false);
xhr.send();
CONFIG = JSON.parse(xhr.responseText);


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

const GLOBALS = Object.freeze(Object.assign({}, SHARED_GLOBALS, {
  APPLICATION: {
    APP_ID: "kobowebrx",
    VERSION: PKG.version,
    NAME: "Kobo Reader"
  },

  BUILDINFO: BUILDINFO,

  DEPLOYMENT: CONFIG.DEPLOYMENT,

  LOCALE: {
    FALLBACK: 'en-US',
    NAMESPACE: 'application',
    NAMESPACES: [
      'a11y',
      'application',
      'error'
    ],
    SUPPORTED: [
      'da-DK',
      'de-DE',
      'en-US',
      'es-ES',
      'es-MX',
      'fi-FI',
      'fr-FR',
      'it-IT',
      'ja-JP',
      'nl-NL',
      'no-NO',
      'pt-PT',
      'sv-SE',
      'tr-TR',
      'zh-TW'
    ]
  },

  MONITORING: {
    DATADOG_APP_ID: '3459caab-ddb0-47cc-a797-acb5cbb84bfa',
    DATADOG_CLIENT_TOKEN: 'pub824192e6dd0c9cc657efa5d002a427d4',
    DATADOG_PREFIX: 'kobo-'
  },

  READINGSERVICES: CONFIG.READINGSERVICES,

  RX: Object.assign({}, SHARED_GLOBALS.RX, {
    RENDERING_ENGINE: {
      DEFAULT: 'ur', // if 'auto' or undefined, the engine will be picked up automatically
      SUPPORTED: ['ur', 'teng'] // list of supported engines
    },
    UI_FADEOUT_TIME: {
      ...SHARED_GLOBALS.RX.UI_FADEOUT_TIME,
      INITIAL: 5000
    }
  }),

  THEME: {
    FALLBACK: 'theme-kobo-light'
  },

  TIMEOUT: {
    WINDOW_RESIZE_DEBOUNCE: 100, // time in ms; debounce of resize event handler
    WINDOW_RESIZE_DEBOUNCE_SHORT: 10 // time in ms; debounce of resize event handler (shorter interval)
  },

  URL: {
    PARAMS: {
      VALID: [
        // public
        'backref_url',
        'end_of_book',
        'engine',
        'epub_url',
        'locale',
        'purchase_url',
        'theme',

        // development/debugging/automation
        'loglevel',
        "qaToolbarTiming"
      ]
    }
  },

  ANALYTICS: Object.assign({}, SHARED_GLOBALS.ANALYTICS, {
    GOOGLE: Object.assign({}, SHARED_GLOBALS.ANALYTICS.GOOGLE, {
      ID: 'G-P1RF2V798S'
    })
  })
}));


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default GLOBALS;
