import color from "./color-palette";

const COLORS_DARK = {

  primary:                   color.tolinoBlue,
  primaryDisabled:           color.quickSilver50,
  primaryHover:              color.tolinoBlue12,
  primarySelected:           color.tolinoBluePastel,
  primaryPressed:            color.tolinoBlueTinted,

  icon:                      color.lightGrey,
  iconDisabled:              color.white48,
  iconHover:                 color.white08,
  iconSelected:              color.white24,
  iconPressed:               color.cloudGrey32,

  label:                     color.gentleGrey,
  divider:                   color.darkCharcoal,
  textHeadline:              color.quickSilver,
  text:                      color.lightGrey,
  background:                color.black,
  backgroundUI:              color.charcoal,

  highlight:                 color.lightYellow,
  highlightSearch:           color.tolinoBluePastel,
  highlightAlpha:            color.gentleYellow,
  alert:                     color.orangeRed,

  // Exceptions
  iconActive:                color.white,
  tabsBackground:            color.darkCharcoal,  // color of dark/divider
  tabBackground:             color.darkCharcoal,
  tabBackgroundHover:        color.white,
  tabBackgroundActive:       color.black,
  tabBackgroundActiveHover:  color.tolinoBlue12,   // color of dark/primaryHover

  lightBackgroundUI:         color.altWhite,       // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIcon:                 color.slateGrey,      // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconDisabled:         color.black42,        // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconHover:            color.black04,        // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconSelected:         color.black12,        // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconPressed:          color.slateGrey16,    // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightPrimary:              color.tolinoBlue,     // for theme independent iconButtons (Zoom UI -Floating -Navbar)

  invertedBackground:        color.white,
  invertedText:              color.black,
  invertedActionBtn:         color.tolinoBlue,

  iconDarkThemeHover:        color.white08,
  iconLightThemeHover:       color.black04
};

export default COLORS_DARK;
