import color from "./color-palette";

const COLORS_DARK = {
  primary:                   color.white,
  primaryDisabled:           color.white48,
  primaryHover:              color.white08,
  primarySelected:           color.white24,
  primaryPressed:            color.white32,

  icon:                      color.white,
  iconDisabled:              color.white48,
  iconHover:                 color.white08,
  iconSelected:              color.white24,
  iconPressed:               color.white32,

  label:                     color.white48,
  divider:                   color.white24,
  textHeadline:              color.white67,
  text:                      color.white67,
  background:                color.black,
  backgroundUI:              color.darkCharcoal,

  highlight:                 color.coolYellow,
  highlightSearch:           color.coolYellow,
  highlightAlpha:            color.coolYellow,
  alert:                     color.coolYellow,

  // Exceptions
  iconActive:                color.white24,
  iconFilled:                color.white,
  toggleActive:              color.white67,
  tabsBackground:            color.transparent,
  tabBackground:             color.transparent,
  tabBackgroundHover:        color.white08,         // color of dark/iconHover
  tabBackgroundActive:       color.transparent,
  tabBackgroundActiveHover:  color.white08,         // color of dark/primaryHover

  lightBackgroundUI:         color.white,           // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIcon:                 color.black,           // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconDisabled:         color.black42,         // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconHover:            color.black04,         // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconSelected:         color.black12,         // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconPressed:          color.darkCharcoal16,  // for theme independent iconButtons (Zoom UI -Floating -Navbar)

  invertedBackground:        color.white,
  invertedText:              color.black,
  invertedActionBtn:         color.koboRed,

  iconDarkThemeHover:        color.white08,
  iconLightThemeHover:       color.black04
};

export default COLORS_DARK;
