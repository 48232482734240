/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 *
 * Global variable definitions that can be used throughout all Kobo
 * applications. These are Kobo specific, but not app specific. For
 * application specific global variables see the GLOBALS.js file in each
 * application's source code folder.
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import MONOREPO_GLOBALS from '~configuration/GLOBALS';


// -----------------------------------------------------------------------------
// GLOBAL DEFINITIONS
// -----------------------------------------------------------------------------

const GLOBALS = Object.freeze(Object.assign({ }, MONOREPO_GLOBALS, {
  ANALYTICS: {
    GOOGLE: {
      EVENTS: {
        BOOK_PROGRESS: 'book_progress',
        CHANGE_FONT: 'change_font',
        FONT_SIZE_CHANGED: 'font_size_changed',
        FONT_WEIGHT_CHANGED: 'font_weight_changed',
        JUSTIFICATION_CHANGED: 'justification_changed',
        LEAVE_CONTENT: 'leave_content',
        LETTER_SPACING_CHANGED: 'letter_spacing_changed',
        LINE_SPACING_CHANGED: 'line_spacing_changed',
        MARGIN_CHANGED: 'margin_changed',
        MULTI_COLUMN_VIEW: 'multi_column_view',
        OPEN_CONTENT: 'open_content',
        PAGE_TRANSITION_CHANGE: 'page_transition_change',
        PUBLISHER_DEFAULT: 'publisher_default',
        READING_NAVIGATION_BREADCRUMBS: 'reading_navigation_breadcrumbs',
        READING_SCRUBBER_ARROWS: 'reading_scrubber_arrows',
        READING_SETTINGS_OPENED: 'open_reading_settings_menu',
        TEMPLATE_SELECTED: 'template_selected',
        TOC_OPENED: 'table_of_contents',
        TURN_PAGE: 'turn_page',
        WORD_SPACING_CHANGED: 'word_spacing_changed',
        ANNOTATIONS_LIST_TAPPED :'annotations_list_tapped',
        ANNOTATIONS_LIST_DELETED :'annotations_list_deleted',
        CLOSE_ANNOTATIONS_LIST:'close_annotations_list',
        OPEN_ANNOTATIONS_LIST: 'open_annotations_list'
      },
      ID_EVENT: 'event',
      PROGRESS_COUNT: [25, 50, 75, 90, 100]
    }
  },

  APPSTATE: {
    ENGINES: {
      INDEXEDDB: {
        DEFAULT_OBJECT_STORE_NAME: 'kobo',
        VERSION: 1
      },
      SQLITE: {
        DB_NAME: 'kobo.sqlite',
        DB_LOCATION: 'default',
        DB_VERSION: "1.0",
        TABLE_NAME: 'store'
      }
    },
    KEYPREFIX: 'kobo:',
    STORAGES: {
      APP: {
        NAME: 'app',
        VERSION: 1
      },
      INVENTORY: {
        NAME: 'inventory',
        VERSION: 1
      },
      RX: {
        NAME: 'rx',
        VERSION: 1
      }
    }
  },

  MEDIA_QUERY: {
    BREAKPOINT_IDS: {
      XSMALL: 'extraSmallScreen',
      SMALL: 'smallScreen',
      MEDIUM: 'mediumScreen',
      LARGE: 'largeScreen',
      XLARGE: 'extraLargeScreen'
    },
    BREAKPOINTS: {
      XSMALL: 0,
      SMALL: 480,
      MEDIUM: 768,
      LARGE: 1024,
      XLARGE: 1440
    },
    ORIENTATION: {
      LANDSCAPE: 'landscape',
      PORTRAIT: 'portrait'
    }
  },

  MEMORY: {
    CACHED_IMAGE_BLOBS: {
      DEFAULT: 20,
      MOBILE: 10,
      MOBILE_INCOGNITO: 5
    }
  },


  MESSAGE_BUS: {
    CHANNEL: {
      APP: 'app',
      SERVICE: 'service',
      READER: 'reader'
    },

    MESSAGE: {
      APP_DEVELOP_LOGIN: 'app/iframeLogin',
      APP_READY: 'app/ready',
      APP_RESET: 'app/reset',
      APP_WINDOW_RESIZE: 'app/windowResize',
      APP_VISIBILITY_CHANGED: 'app/visibilityChanged',
      DEVICE_BREAKPOINT_CHANGE: 'device/breakpointChange',
      DEVICE_DIMENSIONS_CHANGE: 'device/dimensionsChange',
      DEVICE_ORIENTATION_CHANGE: 'device/orientationChange',
      END_OF_BOOK: 'endOfBook',
      NETWORK_STATE_CHANGE: 'network/stateChange',
      RX_POPUP_VISIBILITY: 'rx/popup/visibility',
      RX_PAGE_CHANGED: 'rx/page/changed',
      SERVICE_BACKEND_NETWORK_ERROR: 'service/backend/networkError',
      SERVICE_BACKEND_HTTP_ERROR: 'service/backend/httpError',
      SERVICE_OAUTH_NO_TOKEN:'service/oauth/noToken'
    }
  },

  NETWORK: Object.assign({}, MONOREPO_GLOBALS.NETWORK, {
    TIMEOUT: 10000,  // in milliseconds

    RANGE_REQUESTS: Object.assign({}, MONOREPO_GLOBALS.NETWORK.RANGE_REQUESTS, {
      WHITE_LISTED_DOMAINS: [
        'getbook.kobo.com',
        'getbookstage.kobo.com'
      ]
    })
  })

}));


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default GLOBALS;
