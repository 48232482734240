/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */


// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import { PURGE as REDUX_PURGE } from 'redux-persist';

// Internal dependencies
import { resolveThemeName } from '@rakuten/common-util/theme';
import { validateSchema } from '@rakuten/schemas/utils';
import koboWebRXSchema from '@rakuten/schemas/reducers/kobowebrx.schema';
import {
  APP_LOCALE_CHANGED,
  APP_THEME_CHANGED,
  APP_URLPARAMS_ADDED
} from './../actions/types';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

const DEFAULT_STATE = {
  locale: null,
  theme: 'theme-kobo-light',
  urlParams: null
};


// -----------------------------------------------------------------------------
// APP REDUCER
// -----------------------------------------------------------------------------

function app(state, action) {
  const theme = resolveThemeName(action.payload?.theme, state?.theme);
  let newState = state || DEFAULT_STATE;

  switch (action.type) {
    case APP_LOCALE_CHANGED:
      if (state.locale !== action.payload.locale) {
        newState = {
          ...state,
          locale: action.payload.locale
        };
      }
    break;

    case APP_THEME_CHANGED:
      if (state.theme !== theme) {
        newState = {
          ...state,
          theme
        }
      }
    break;

    case APP_URLPARAMS_ADDED:
      if (state.urlParams !== action.payload.urlParams) {
        newState = {
          ...state,
          urlParams: action.payload.urlParams
        }
      }
    break;

    case REDUX_PURGE:
      newState = DEFAULT_STATE;
    break
  }

  validateSchema(newState, koboWebRXSchema);

  return newState;
}


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default app;
