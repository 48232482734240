/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// External/Third-party dependencies
import JSLogger from 'js-logger';

// Internal dependencies
import GLOBALS from '~configuration/GLOBALS';


// -----------------------------------------------------------------------------
// CONFIGURATION
// -----------------------------------------------------------------------------

// List of context identifiers for which not to output TAG
const IGNORED_CONTEXTS = [ 33 ];

// Tag that will be printed in front of every log message; exceptions are
// defined in IGNORED_CONTEXTS
const GLOBAL_TAG = '[tolino]';

// Tag for log output etc.
const TAG = '[service/logger]';

JSLogger.useDefaults({
  formatter: function (messages, context) {
    // For some contexts, we do not want to show the additional [tolino] output,
    // e.g. tables
    if (IGNORED_CONTEXTS.indexOf(context.level.value) === -1) {
      // String could potentially hold %c, which identifies color information
      // within messages. To not destroy color information, we append the global
      // tag to the first message instead of adding a new element to the
      // messages arguments
      if (messages[0].constructor === String) {
        messages[0] = `${GLOBAL_TAG} ${messages[0]}`;
      } else {
        messages.unshift(GLOBAL_TAG);
      }
    }
  }
});

// -----------------------------------------------------------------------------
// PRIVATE MODULE METHODS
// -----------------------------------------------------------------------------

/**
 * Mappings for `js-logger` log levels. Log level can be provided either as
 * number or as key. For available log levels, see
 * https://github.com/jonnyreeves/js-logger/blob/master/src/logger.js#L50-L56
 *
 * @param {Number|String} level Log level to map
 * @returns {String|null} The mapped logged level
 */
const _mapLogLevel = level => {
  // At first, try to get the proper level name if the level is provided as
  // an Integer
  try {
    let intLevel = parseInt(level, 10);
    if (isNaN(intLevel) === false) {
      return Object.keys(GLOBALS.LOGGING.LEVELS).
        find(key => GLOBALS.LOGGING.LEVELS[key] === intLevel)
    }
  } catch (e) {
    JSLogger.error(`${TAG} Uncaught error`, e);
  }

  // Secondly, try to determine the loglevel by key name. We could have just
  // returned the upper case version of the provided level, but this way, we
  // can directly see if the log level is supported, i.e. if it is not in
  // GLOBALS.LOGGING.LEVEL.
  return Object.keys(GLOBALS.LOGGING.LEVELS).
    find(key => key === level.toUpperCase());
};


// -----------------------------------------------------------------------------
// LOGGER SERVICE
// -----------------------------------------------------------------------------

const logger = {

  // ---------------------------------------------------------------------------
  // PRIVATE METHODS
  // ---------------------------------------------------------------------------

  /**
   * Re-route all errors that bubble up to the window object to `js-logger`
   */
  reRouteErrorHandler: () => {
    /**
     * This will handle all uncaught exceptions. For now, we log those exceptions
     * to the console, just as they would normally do. In the future, we may
     * send those to an analytics tool, e.g. Firebase
     *
     * @param {String} message An error message
     * @param {String} source URL of the script where the error was raised
     * @param {Number} line Line number where error was raised
     * @param {Number} colno Column number for the line where the error occurred
     * @param {Object} error an Error object
     * @returns {Boolean} Whether to prevent firing the default error handler or not
     */
    const __onError = (message, source, line, colno, error) => {
      if (error?.shouldWriteToLogger !== true) {
        JSLogger.error(`${TAG} Uncaught error`, error);
      }

      // Will prevent the default output to the console
      return true;
    };

    window.onerror = __onError;
  },


  /**
   * Sets `js-logger`'s log level to the provided log level (duh!)
   *
   * @param {String} level The key/name of the log level
   */
  setLogLevel: level => {
    let loglevel = _mapLogLevel(level);
    if (!loglevel) {
      console.warn(`${TAG} Not able to set log level to "${level}". Falling back to default log level -> ${GLOBALS.LOGGING.DEFAULT_LEVEL}.`);
      loglevel = _mapLogLevel(GLOBALS.LOGGING.DEFAULT_LEVEL)
    }

    console.info(`${TAG} Setting log level to ${loglevel}`);
    JSLogger.setLevel(JSLogger[loglevel]);
  },


    /**
   * Gets the currently activated loglevel
   *
   * @return {object} JSLogger log level info, e.g. { value: 2, name: "DEBUG" }
   */
  getLogLevel: JSLogger.getLevel,


  // Reroute log methods to JSLogger
  log: JSLogger.log,
  debug: JSLogger.debug,
  info: JSLogger.info,
  warn: JSLogger.warn,
  error: JSLogger.error,
  trace: JSLogger.trace,
  group: JSLogger.group,
  groupCollapsed: JSLogger.groupCollapsed,
  groupEnd: JSLogger.groupEnd,
  table: JSLogger.table
};


// -----------------------------------------------------------------------------
// SERVICE REGISTRATION
// -----------------------------------------------------------------------------

//service config for service locator
export const config = ["logger", [], () => logger];

// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default logger;
